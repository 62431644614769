import { ninjaConfig } from '../config/ninja';
// import { trackDebugInfo } from '../core';
import { cleanCookie } from './cleanCookie';

const cookieRegexCache = {};

/**
 * Gets cookie name, based on configs, and cleans up all sub-domain and paths
 * @param {string} cookieName
 * @returns {string}
 */
export function getCookieName(cookieName) {
  let cname = cookieName;
  let strRegExPattern = cookieRegexCache[cname];

  if (!strRegExPattern) {
    strRegExPattern = new RegExp('\\b' + cname + '\\b', 'gi');
    cookieRegexCache[cname] = strRegExPattern;
  }

  // Fix any problem with the cookies
  if (ninjaConfig.environment === 'production') {
    const count = (document.cookie.match(strRegExPattern) || []).length;

    if (count > 1) {
      // log the cookie duplication - these messages should get close to 0 with time
      // trackDebugInfo('Cookie duplication: ' + cname + ', ' + count, true);

      // clean cookies only for allowed domains
      cleanCookie(cname, [ninjaConfig.cookieDomain]);
    }
  }

  if (cname === 'onap') {
    if (ninjaConfig.environment !== 'production') {
      cname += '_dev';
    }
  }

  return cname;
}
