import { hydraConfig } from '../../config/hydra';
import { ninjaConfig } from '../../config/ninja';
import { EVENTS, JSQR_URL, LAQUESIS_QA_URL, LQ_EVENTS, SPLITTER_CDN, TEST_DIVIDER, VALUE_DIVIDER, VARIANT_DIVIDER } from '../../const';
import { cookieStorage, getCookieExpirationDate, getCookieName } from '../../cookies';
import { getLQDefinition, writeLQDefinition } from '../../cookies/lqstatus';
import { trackError } from '../../core/utils';
import { collectCalls } from '../../core/utils';
import { ajaxCallPromise, deucex, getNow, loadScript, objectToQueryString } from '../../utils';
import { triggerCustomEvent } from '../../utils/event';
import { hasUserInteractedWithPage } from '../../web-vitals';
import { firstTrackPage } from '../hydra';
import * as surveys from './surveys';
import { getSurveys } from './surveys/surveyStorage';

let currentLqPush;

let retries = 0;
let trackPageRetries = 0;
let initPushed = false;
let listOfActiveTests = {};
let listOfActiveFlags = {};

/**
 * @type {import('../../cookies/lqstatus').LQDefinition}
 */
let lqDefinition = {};
let localTestsCookieValue = null;
let localFlagsCookieValue = null;

const cookieName = getCookieName('laquesis');
const cookieNameFf = getCookieName('laquesisff');
const cookieNameQa = getCookieName('laquesisqa');

// Link event
let isLinkEvent = false;

// QA
let qa = {
  enable: false,
  icon: true,
  style: true,
  expires: 2 * 60,
};

/**
 * Fetches fresh definitions
 */
export function fetchDefinitions() {
  const params = {};

  params.sl = ninjaConfig.currentSessionLong;
  if (ninjaConfig.userId) {
    params.ui = ninjaConfig.userId;
  }
  params.cc = hydraConfig.params.cC;
  params.ch = ninjaConfig.platform;

  params.br = hydraConfig.params.bR || 'olx';

  const url = `${SPLITTER_CDN}/assign?${objectToQueryString(params)}`;

  return ajaxCallPromise('get', url);
}

export function reloadLaquesisDefinitions() {
  return fetchDefinitions()
    .then(data => processDefinition(data))
    .catch(error => trackError('fetchDefinitionsError', 'Laquesis', 'reloadLaquesisDefinitions', error));
}

export async function init() {
  let qaMode = false;
  let now = null;
  let mustRefresh = false;
  let split = null;
  let qaTestsCookie;
  let qaFlagsCookie;
  let qaSurveyId;

  if (navigator.cookieEnabled) {
    if (ninjaConfig.currentSessionLong) {
      // QA Config
      qa = {
        enable: false,
        icon: true,
        style: true,
        expires: 2 * 60,
      };
      if (ninjaConfig?.laquesisQa) {
        if (ninjaConfig.laquesisQa.enable) {
          qa.enable = Boolean(ninjaConfig.laquesisQa.enable);
        }
        if (ninjaConfig.laquesisQa.icon) {
          qa.icon = Boolean(ninjaConfig.laquesisQa.icon);
        }
        if (ninjaConfig.laquesisQa.style) {
          qa.style = Boolean(ninjaConfig.laquesisQa.style);
        }
        if (ninjaConfig.laquesisQa.expires) {
          qa.expires = parseInt(ninjaConfig.laquesisQa.expires.toString(), 10);
        }
        if (ninjaConfig.laquesisQa.experiments) {
          qa.experiments = ninjaConfig.laquesisQa.experiments;
        }
        if (ninjaConfig.laquesisQa.flags) {
          qa.flags = ninjaConfig.laquesisQa.flags;
        }
      }

      try {
        const searchParams = new URL(window.location).searchParams;

        if (searchParams.has('laquesisqahash')) {
          split = atob(deucex(searchParams.get('laquesisqahash'))).split('!');
          if (split.length <= 4) {
            qaTestsCookie = split[0];
            // qaEnvironment = split[1];
            qaFlagsCookie = split[2];
            qaSurveyId = split[3];

            if (qaTestsCookie) {
              // Fixed list of experiments
              localTestsCookieValue = qaTestsCookie.toLowerCase();
              // Save in the cookie to enable backend experiments
              cookieStorage.set(cookieName, localTestsCookieValue, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }

            if (qaFlagsCookie) {
              // Fixed list of flags
              localFlagsCookieValue = qaFlagsCookie.toLowerCase();
              // Save in the cookie to enable backend flags
              cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }

            await surveys.setQASurvey(qaSurveyId);

            if (qaTestsCookie || qaFlagsCookie || qaSurveyId) {
              // qaMode true so no refresh
              qaMode = true;
              // Disable the QA menu
              qa.enable = false;
              // Flag that it is in QAMODE so the refresh will not work
              cookieStorage.set(cookieNameQa, 1, {
                expires: getCookieExpirationDate(qa.expires, 'min'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }
          }
        }
      } catch (e) {
        return false;
      }

      if (ninjaConfig.environment !== 'production') {
        if (qa.enable === true) {
          if (undefined !== qa.experiments) {
            // Fixed list of experiments
            localTestsCookieValue = qa.experiments.toLowerCase();
            // Save in the cookie to enable backend experiments
            cookieStorage.set(cookieName, localTestsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });
            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
          if (undefined !== qa.flags) {
            // Fixed list of flags
            localFlagsCookieValue = qa.flags.toLowerCase();
            // Save in the cookie to enable backend flags
            cookieStorage.set(cookieNameFf, localFlagsCookieValue, {
              expires: getCookieExpirationDate(qa.expires, 'min'),
              path: '/',
              domain: ninjaConfig.cookieDomain,
            });

            // qaMode true so no refresh
            qaMode = true;
            // Disable the QA menu
            qa.enable = false;
          }
        }
      }

      if (cookieStorage.get(cookieNameQa)) {
        // If the laquesisqa is still there

        // show survey in QA mode
        ninjaConfig.qaMode = true;

        // do not refresh
        qaMode = true;

        // Disable the QA menu
        qa.enable = false;
      }

      // Read the definition only ones
      if (!localTestsCookieValue) {
        localTestsCookieValue = cookieStorage.get(cookieName);
      }
      if (!localFlagsCookieValue) {
        localFlagsCookieValue = cookieStorage.get(cookieNameFf);
        if (!localFlagsCookieValue) {
          localFlagsCookieValue = cookieStorage.get('laquesis_ff');
        }
      }
      mustRefresh = surveys.init() || mustRefresh;

      // Read the status
      lqDefinition = getLQDefinition();

      if (lqDefinition.nextRefresh === -1) {
        // Refresh because there is no cookie yet
        mustRefresh = true;
      } else {
        // Check the expired time
        now = getNow();
        if (lqDefinition.nextRefresh - now < 0) {
          // Refresh because the definition is expired
          mustRefresh = true;
        }

        // check if there's a mismatch between saved definitions and current status
        if (lqDefinition.isDefinitionsForUserId && !ninjaConfig.userId) {
          // we have userId definitions, but ninja is not initialized with userId => fetch sl definitions
          mustRefresh = true;
          lqDefinition.isDefinitionsForUserId = false;
        } else if (!lqDefinition.isDefinitionsForUserId && ninjaConfig.userId) {
          // we have sl definitions, but ninja is not initialized with userId => fetch userId definitions
          mustRefresh = true;
          lqDefinition.isDefinitionsForUserId = true;
        }
      }

      if (mustRefresh && qaMode === false) {
        await reloadLaquesisDefinitions();
      } else {
        if (localTestsCookieValue) {
          parseLaquesisCookie(localTestsCookieValue);

          if (ninjaConfig.cookieFromLq) {
            // New user, the cookie and definition comes from the backend
            // ninjaConfig.cookieFromLq = false; // TODO:
            // Fix for some servers that quotes the cookie when contains @ :: Overwrite laquesis without quotes
            if (localTestsCookieValue) {
              localTestsCookieValue = localTestsCookieValue.replace(/^"(.+)"$/, '$1');
              cookieStorage.set(cookieName, localTestsCookieValue, {
                expires: getCookieExpirationDate(1, 'year'),
                path: '/',
                domain: ninjaConfig.cookieDomain,
              });
            }
            trackTestsAssignment();
          }
        }

        if (localFlagsCookieValue) {
          parseLaquesisFfCookie(localFlagsCookieValue);
        }
      }

      // Watch the laquesis cookie
      if (initPushed === false) {
        initPushed = true;

        // expose functions to `window`
        setupWindow();

        // FIXME: should wait for processDefinition?
        // Call function when laquesis is loaded and ready to use
        if (undefined !== window.laquesisFunctionsCallback || typeof window.laquesisFunctionsCallback === 'function') {
          window.laquesisFunctionsCallback.call(null);
        }

        // Setup laquesisResults
        window.laquesisResults = window.laquesisResults || [];

        // Overwrite function push
        currentLqPush = window.laquesisResults.push;
        window.laquesisResults.push = function (params) {
          let result = currentLqPush.apply(window.laquesisResults, [params]);

          checkResults();

          return result;
        };

        // Set internal queue
        if (!window.laquesisQueue) {
          window.laquesisQueue = [];
        }
        window.laquesisQueue.push = function (func) {
          if (typeof func === 'function') {
            func();
          }
        };
        while (window.laquesisQueue.length > 0) {
          window.laquesisQueue.push(window.laquesisQueue.shift());
        }

        checkResults();
      }

      // FIXME: why we need this to run every 5 min?
      // Check the status again every 5 minutes
      setTimeout(init, 1000 * 60 * 5);
    } else {
      // The session_long do not exists yet, retry 5 times
      if (retries < 5) {
        retries = retries + 1;
        setTimeout(init, 500);
      }
    }
  }
  return true;
}

// Expose functionality to `window`
export function setupWindow() {
  // Expose function
  window.isVariantEnabled = function (testName, variantName, delayTracking, trackOrder) {
    return isVariantEnabled(testName, variantName, delayTracking, trackOrder);
  };

  // Expose function
  window.getLaquesisVariant = function (testName, delayTracking, trackOrder) {
    return getLaquesisVariant(testName, delayTracking, trackOrder);
  };

  // Expose function
  window.isFeatureEnabled = function (flagName) {
    return isFeatureEnabled(flagName);
  };

  // Expose function
  window.laquesisSetUserId = function (id) {
    return laquesisSetUserId(id);
  };

  // Expose function
  window.laquesisDropUserId = function () {
    return laquesisDropUserId();
  };

  if (ninjaConfig.environment !== 'production') {
    if (qa.enable === true) {
      // Call laquesisqa.js as external file to do not load it on production
      loadScript(LAQUESIS_QA_URL, 'laquesisqa', function () {
        loadScript(JSQR_URL, 'laquesisqr', function () {
          // @ts-ignore
          window.laquesisqa(
            {
              listOfActiveTests: listOfActiveTests,
              listOfActiveFlags: listOfActiveFlags,
              qa: qa,
              params: hydraConfig.params,
            },
            ninjaConfig
          );
        });
      });
    }
  }

  window.laquesisGetActive = function () {
    const lqdef = getLQDefinition();

    // return a copy of current state
    return {
      experiments: { ...listOfActiveTests },
      flags: Object.keys(listOfActiveFlags),
      surveys: {
        assigned: getSurveys().map(e => Number(e.id)),
        shown: lqdef.showedSurveys.map(Number),
      },
    };
  };

  surveys.setupWindow();
}

// Collect the data from the API
export function processDefinition(newDefinition) {
  let config = null;
  let newCookieValue;
  let newFfCookieValue;
  let now = null;
  let testCookie = null;

  if (newDefinition) {
    config = JSON.parse(newDefinition);
    surveys.setSurveys(config.surveys);

    newCookieValue = parseTestsJson(config.tests);
    newFfCookieValue = parseFlagsJson(config.flags);

    // Set the next refresh
    now = getNow();
    lqDefinition.nextRefresh = now + parseInt(config.config.next_update_foreground_in_minutes, 10) * 60;
    writeLQDefinition(lqDefinition);

    // Track and store the assignment if it is different
    if (localTestsCookieValue !== newCookieValue) {
      // Save the new cookie value
      localTestsCookieValue = newCookieValue;
      cookieStorage.set(cookieName, newCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackTestsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_ASSIGNMENT, { experiments: newCookieValue });
    }

    // Store the flags if it is different
    if (localFlagsCookieValue !== newFfCookieValue) {
      // Save the new cookie value
      localFlagsCookieValue = newFfCookieValue;
      testCookie = cookieStorage.get('laquesis_ff');
      if (testCookie) {
        // Remove old cookie
        cookieStorage.remove('laquesis_ff', {
          path: '/',
          domain: ninjaConfig.cookieDomain,
        });
      }
      cookieStorage.set(cookieNameFf, newFfCookieValue, {
        expires: getCookieExpirationDate(1, 'year'),
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });

      // Track the assignment
      trackFlagsAssignment();
      triggerCustomEvent(EVENTS.LAQUESIS_FLAG_ASSIGNMENT, { flags: newFfCookieValue });
    }

    // Call function when assign is loaded and ready to use. @deprecated
    if (window.laquesisAssignCallback || typeof window.laquesisAssignCallback === 'function') {
      window.laquesisAssignCallback.call(null);
    }

    triggerCustomEvent(EVENTS.LAQUESIS_READY, null, document);
  }
}

export function parseTestsJson(tests) {
  let result = '';

  tests.sort((a, b) => a.tN.localeCompare(b.tN));
  listOfActiveTests = {};

  for (let i = 0; i < tests.length; i += 1) {
    tests[i].tN = tests[i].tN.toLowerCase();
    tests[i].vN = tests[i].vN.toLowerCase();
    result += TEST_DIVIDER + tests[i].tN + VARIANT_DIVIDER + tests[i].vN;
    listOfActiveTests[tests[i].tN] = tests[i].vN;
  }

  return result.substring(1);
}

export function parseFlagsJson(flags) {
  let result = '';

  flags.sort((a, b) => a.tN.localeCompare(b.tN));
  listOfActiveFlags = {};
  for (let i = 0; i < flags.length; i += 1) {
    flags[i].tN = flags[i].tN.toLowerCase();
    result += TEST_DIVIDER + flags[i].tN;
    listOfActiveFlags[flags[i].tN] = 1;
  }

  return result.substr(1);
}

// Return if the testName with that variantValue is enable or not for this user
export function isVariantEnabled(testName, variantValue, delayTracking, trackOrder) {
  let foundTest = false;
  let testNameLower = testName.toLowerCase();
  let variantValueLower = variantValue.toLowerCase();

  if (!navigator.cookieEnabled) {
    return false;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    if (listOfActiveTests[testNameLower] === variantValueLower) {
      foundTest = true;
      trackImpression(testNameLower, variantValueLower, delayTracking, trackOrder);
      triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
        experiment: testNameLower,
        variant: variantValueLower,
        isDelayedTracking: delayTracking,
        trackOrder: trackOrder,
      });
    }
  }

  return foundTest;
}

// Return the variant for testName or null
export function getLaquesisVariant(testName, delayTracking, trackOrder) {
  let variantValue = null;
  let testNameLower = testName.toLowerCase();

  if (!navigator.cookieEnabled) {
    return null;
  }

  if (undefined !== listOfActiveTests[testNameLower]) {
    variantValue = listOfActiveTests[testNameLower];
    trackImpression(testNameLower, variantValue, delayTracking, trackOrder);
    triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
      experiment: testNameLower,
      variant: variantValue,
      isDelayedTracking: delayTracking,
      trackOrder: trackOrder,
    });
    return variantValue;
  }

  return null;
}

// Return if the flagName is enable or not for this user
export function isFeatureEnabled(flagName) {
  if (!navigator.cookieEnabled) {
    return false;
  }

  return undefined !== listOfActiveFlags[flagName.toLowerCase()];
}

// Set userId
export function laquesisSetUserId(id) {
  if (id && ninjaConfig.userId !== id) {
    ninjaConfig.userId = id;
    reloadLaquesisDefinitions();
  }
}

// Drop userId
export function laquesisDropUserId() {
  if (ninjaConfig.userId) {
    ninjaConfig.userId = null;

    reloadLaquesisDefinitions();
  }
}

// Entry function - Parse the data in the array
export function checkResults() {
  function c(cookieValue) {
    parseLaquesisCookie(cookieValue);
  }

  for (const [index, data] of Object.entries(window.laquesisResults)) {
    if (typeof data === 'object' && !data.processed) {
      if (undefined !== data.newResult) {
        // Queue the call to the function
        window.laquesisResults[index].processed = true;
        window.laquesisQueue.push(c(data.newResult));
      }
    }
  }
}

// Track the tests assignment
export function trackTestsAssignment(delayTracking, trackOrder) {
  let testDefinition = Object.entries(listOfActiveTests)
    .map(([k, v]) => `"${k},${v}"`)
    .join(',');
  testDefinition = `[${testDefinition}]`;

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: [LQ_EVENTS.TEST_ASSIGNMENT],
      test_definition: testDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: [LQ_EVENTS.TEST_ASSIGNMENT],
      test_definition: testDefinition,
    });
  }
}

// Track the flags assignment
export function trackFlagsAssignment(delayTracking, trackOrder) {
  const flagDefinition =
    '[' +
    Object.keys(listOfActiveFlags)
      .map(e => `"${e}"`)
      .join(',') +
    ']';

  if (delayTracking) {
    ninjaConfig.dataLayerDelayed.push({
      trackOrder: trackOrder,
      trackEvent: [LQ_EVENTS.FLAG_ASSIGNMENT],
      flag_definition: flagDefinition,
    });
  } else {
    cleanupTracking();
    ninjaConfig.dataLayer.push({
      trackEvent: [LQ_EVENTS.FLAG_ASSIGNMENT],
      flag_definition: flagDefinition,
    });
  }
}

// Track the impression only one time per session per test
export function trackImpression(testName, variantValue, delayTracking, trackOrder) {
  let i;
  let trackedTests;
  let foundTrackedTest = false;
  let params = {};
  lqDefinition = getLQDefinition();

  if (!lqDefinition.currentSession || lqDefinition.currentSession !== ninjaConfig.currentSession) {
    // If there is no session or is different, start from 0
    lqDefinition.currentSession = ninjaConfig.currentSession;
    lqDefinition.trackedExperiments = ''; // Tracked tests
  } else {
    // If the session is the same, find the test
    if (lqDefinition.trackedExperiments) {
      trackedTests = lqDefinition.trackedExperiments.split(TEST_DIVIDER);
      for (i = 0; i < trackedTests.length; i += 1) {
        if (trackedTests[i] === testName) {
          foundTrackedTest = true;
          break;
        }
      }
    }
  }

  // Track the impression only one time per session
  if (!foundTrackedTest) {
    // Add the test and save the cookie
    if (!lqDefinition.trackedExperiments || lqDefinition.trackedExperiments.length === 0) {
      lqDefinition.trackedExperiments = testName;
    } else {
      lqDefinition.trackedExperiments += TEST_DIVIDER + testName;
    }
    writeLQDefinition(lqDefinition);

    // Finally track

    if (delayTracking) {
      params = {
        trackOrder: trackOrder,
        trackEvent: [LQ_EVENTS.TEST_IMPRESSION],
        test_name: testName,
        test_variation: variantValue,
      };

      // Once user has interacted with the page vitals are out of scope -> do not send them anymore
      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayerDelayed.push(params);
    } else {
      cleanupTracking();

      params = {
        trackEvent: [LQ_EVENTS.TEST_IMPRESSION],
        test_name: testName,
        test_variation: variantValue,
      };

      if (!hasUserInteractedWithPage) {
        // needed for web vitals to be linked to experiments
        params.web_vital_page = firstTrackPage;
      }

      ninjaConfig.dataLayer.push(params);
    }
  }
}

// Remove all the test properties
export function cleanupTracking() {
  let length = ninjaConfig.dataLayer.length;
  let i;

  for (i = 0; i < length; i = i + 1) {
    if (
      undefined !== ninjaConfig.dataLayer[i].test_definition ||
      undefined !== ninjaConfig.dataLayer[i].test_name ||
      undefined !== ninjaConfig.dataLayer[i].test_variation ||
      undefined !== ninjaConfig.dataLayer[i].flag_definition
    ) {
      ninjaConfig.dataLayer.splice(i, 1);
      i = length;
    }
  }
}

// Read and parse the test cookie
export function parseLaquesisCookie(cookie) {
  let listOfTest;
  let i;
  let nameValue;
  let valueFlag;
  let cookieValue = cookie;

  // Fix for some servers that quotes the cookie when contains @ :: Use value without quotes
  if (cookieValue) {
    cookieValue = cookieValue.replace(/^"(.+)"$/, '$1');
  }

  listOfTest = cookieValue.split(TEST_DIVIDER);
  for (i = 0; i < listOfTest.length; i += 1) {
    nameValue = listOfTest[i].split(VARIANT_DIVIDER);
    if (nameValue.length === 2) {
      valueFlag = nameValue[1].split(VALUE_DIVIDER);
      if (valueFlag.length === 2) {
        // Read the set-cookie from the server
        if (valueFlag[1] === 't') {
          trackImpression(nameValue[0], valueFlag[0]);
          triggerCustomEvent(EVENTS.LAQUESIS_EXP_IMPRESSION, {
            experiment: nameValue[0],
            variant: valueFlag[0],
            isDelayedTracking: false,
            trackOrder: undefined,
          });
          if (undefined === listOfActiveTests[nameValue[0]]) {
            listOfActiveTests[nameValue[0]] = valueFlag[0];
          }
        }
      } else {
        // Read the normal cookie
        if (undefined === listOfActiveTests[nameValue[0]]) {
          listOfActiveTests[nameValue[0]] = valueFlag[0];
        }
      }
    }
  }
}

// Read and parse the flags cookie
export function parseLaquesisFfCookie(cookieValue) {
  let listOfFlags;
  let i;

  listOfFlags = cookieValue.split(TEST_DIVIDER);
  for (i = 0; i < listOfFlags.length; i += 1) {
    // Read the normal cookie
    if (undefined === listOfActiveFlags[listOfFlags[i]]) {
      listOfActiveFlags[listOfFlags[i]] = 1;
    }
  }
}

// Entry function - Track a page
export function trackPage(params) {
  onHitSentOk();
  if (initPushed === true) {
    surveys.trackPage(params);
  } else {
    // If Laquesis is not started, retry 5 times every half second
    if (trackPageRetries < 5) {
      trackPageRetries = trackPageRetries + 1;
      setTimeout(() => trackPage(params), 500);
      return false;
    }
  }

  return true;
}

// Entry function - Track an event
export function trackEvent(params) {
  return trackPage(params);
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

// Internal function - Callback after finish one hit
export function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}

export function getIdentifier() {
  return ninjaConfig.userId || ninjaConfig.currentSessionLong;
}
