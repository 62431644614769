import { ninjaConfig } from '../../../config/ninja';
import { LQ_SURVEYS_STORAGE_KEY } from '../../../const';

export let triggerMap = {};

/**
 * Extract trigger events for easy use. Note that feedback and functional surveys will not be in this list.
 * @param {any[]} surveys
 */
function buildTriggerMap(surveys) {
  triggerMap = {};

  for (const survey of surveys) {
    for (const t of survey.triggers) {
      if (!triggerMap[t.event_name]) {
        triggerMap[t.event_name] = [];
      }
      triggerMap[t.event_name].push(survey.id);
    }
  }

  // console.log(triggerMap, surveys);
}

export function setSurveys(surveys) {
  ninjaConfig.listOfActiveSurveys = surveys;
  window.localStorage.setItem(LQ_SURVEYS_STORAGE_KEY, JSON.stringify(surveys));
  buildTriggerMap(surveys);
}

export function getSurveys() {
  return ninjaConfig.listOfActiveSurveys;
}

export function clear() {
  setSurveys([]);
}

/**
 * Get survey by id
 * @param {number} id
 * @returns {Survey}
 */
export function getSurvey(id) {
  return ninjaConfig.listOfActiveSurveys?.find(s => s.id.toString() === id.toString());
}

/**
 * Get surveys with specific trigger event
 * @param {string} eventName
 * @returns {number[]}
 */
export function getSurveysWithTriggerEvent(eventName) {
  return triggerMap[eventName] ?? [];
}
