import { WEB_VITALS_URL } from './const';
import { loadScript } from './utils';

/**
 * @typedef {('CLS' | 'FCP' | 'FID' | 'INP' | 'LCP' | 'TTFB')} PerformanceMetricName
 *
 * @typedef {Object} PerformanceMetric
 * @prop {PerformanceMetricName} name
 * @prop {string} id
 * @prop {('navigate' | 'reload' | 'back-forward' | 'back-forward-cache' | 'prerender' | 'restore')} navigationType
 * @prop {('good' | 'needs-improvement' | 'poor')} rating - The rating as to whether the metric value is within the "good",
 * "needs improvement", or "poor" thresholds of the metric.
 * The thresholds of metric's "good", "needs improvement", and "poor" ratings.
 *
 * - Metric values up to and including [0] are rated "good"
 * - Metric values up to and including [1] are rated "needs improvement"
 * - Metric values above [1] are "poor"
 *
 * | Metric value    | Rating              |
 * | --------------- | ------------------- |
 * | ≦ [0]           | "good"              |
 * | > [0] and ≦ [1] | "needs improvement" |
 * | > [1]           | "poor"              |
 * @prop {number} value
 * @prop {number} delta
 * @prop {[PerformanceNavigationTiming]} entries
 */

const SCRIPT_TAG_ID = 'web-vitals-script-tag';
let isLoaded = false;

/**
 * Mark when user has interacted with the page
 */
export let hasUserInteractedWithPage = false;

/**
 * Enabled metrics to track
 * @type {PerformanceMetricName[]}
 */
const watchedMetrics = ['FCP', 'LCP', 'CLS', 'TTFB', 'FID', 'INP'];
let trackCallback = null;

export function loadWebVitals(callback) {
  if (isWebVitalsEnabled() && isLoaded === false) {
    isLoaded = true;
    trackCallback = callback;

    if (window.webVitals) {
      setMetricWatchers();
    } else {
      loadScript(WEB_VITALS_URL, SCRIPT_TAG_ID, setMetricWatchers);
    }
  }
}

/**
 * Perform the actual tracking of the metric
 * @param {PerformanceMetric} metric
 */
export function trackWebVitalsMetric(metric) {
  trackCallback(metric);
}

export function setMetricWatchers() {
  if (window.webVitals) {
    for (let i = 0; i < watchedMetrics.length; i++) {
      let method = window.webVitals['on' + watchedMetrics[i]];

      if (typeof method === 'function') {
        method(trackWebVitalsMetric, { reportAllChanges: false });
      }
    }
  } else {
    console.log('No web-vitals lib loaded');
  }
}

export function isWebVitalsEnabled() {
  return !!window.performance && !!window.PerformanceObserver;
}

/**
 * Make sure all browsers mark interaction stop, even if web-vitals is not supported
 * @see https://github.com/GoogleChrome/web-vitals/blob/a948d3cde1770eabb8a215072cfd9713e940c4d9/src/onLCP.ts#L99
 */
function onInteraction() {
  hasUserInteractedWithPage = true;
  window.removeEventListener('click', onInteraction, true);
  window.removeEventListener('keydown', onInteraction, true);
}

window.addEventListener('click', onInteraction, true);
window.addEventListener('keydown', onInteraction, true);
