import { hydraConfig } from '../../../config/hydra';
import { ninjaConfig } from '../../../config/ninja';
import { SPLITTER_CDN, TEST_DIVIDER } from '../../../const';
import { cookieStorage } from '../../../cookies';
import { getLQDefinition, writeLQDefinition } from '../../../cookies/lqstatus';
import { getDefaultParams } from '../../../core/params';
import { ajaxCallPromise, deucex, getCurrentPath, getNow, objectToQueryString } from '../../../utils';
import { SURVEY_LOAD, SURVEY_MARK_SHOWN, debugSurvey } from './surveyDebug';
import { getSurvey, getSurveys } from './surveyStorage';

/**
 * Check if survey is assigned, not shown yet and not in lockout period
 * @param {number} id
 * @returns {boolean}
 */
export function isSurveyAvailable(id) {
  const survey = getSurvey(id);

  // survey is assigned to user
  if (survey) {
    // feedback surveys can always be shown
    if (survey.type === 'feedback') {
      return true;
    }

    // during QA mode all surveys are available
    if (ninjaConfig.qaMode) {
      return true;
    }

    return !isSurveyShown(id) && !isInLockoutPeriod();
  }

  return false;
}

/**
 * Checks if a survey can be shown:
 * - params match triggers
 * - survey is available
 * - survey is not yet shown
 * - user is not in lockout from previous survey
 * @param {number} id
 * @param {string} eventName
 * @param {Record<string, any>} params Current tracking params
 * @returns {TriggerResult}
 */
export function canShowSurvey(id, eventName, params) {
  const survey = getSurvey(id);
  /**
   * @type {TriggerResult}
   */
  let result = {
    matches: false,
    event: eventName,
    shown: isSurveyShown(id),
    in_lockout: isInLockoutPeriod(),
    active_surveys: getSurveys(),
  };

  // survey is assigned to user
  if (survey) {
    // feedback surveys can always be shown
    if (survey.type === 'feedback') {
      result.matches = true;
    } else if (!isSurveyShown(id) && !isInLockoutPeriod()) {
      // use only triggers with matching event
      const matchingTriggers = survey.triggers.filter(t => t.event_name === eventName);
      result = checkTriggers(matchingTriggers, params);
    }
  }
  return result;
}

/**
 * Checks if a given survey's triggers match the current tracked params.
 * @param {SurveyTrigger[]} triggers Survey trigger condition groups
 * @param {Record<string, any>} params Current tracking params
 * @returns {TriggerResult}
 */
export function checkTriggers(triggers, params) {
  // surveys without any conditions can be triggered only with function call
  if (!Array.isArray(triggers) || triggers.length === 0) {
    return {
      matches: false,
    };
  }

  const allParams = params.customParams || {};
  const defaultParams = getDefaultParams(params);

  // Add identifiers
  allParams.sl = params.sessionParams?.sessionLong;
  allParams.s = params.sessionParams?.session;
  allParams.cl = params.sessionParams?.sessionCountLong;
  allParams.c = params.sessionParams?.sessionCount;
  allParams.cp = deucex(getCurrentPath());
  allParams.cc = defaultParams.cC;
  allParams.br = defaultParams.bR;

  const results = triggers.map(trigger => checkConditionGroups(trigger.conditions, allParams));

  // we need any of the triggers to match
  return {
    // empty array will yield false
    matches: results.some(e => e.matches),
    results,
  };
}

/**
 * Checks the tracking params against the conditions in a group
 * @param {SurveyTriggerCondition[]} conditions Trigger conditions, defined by Console UI
 * @param {Record<string, any>} params List of tracking properties and their values
 */
export function checkConditionGroups(conditions, params) {
  // conditions with no groups are controlled only by the event_name, which is already checked
  if (conditions.length === 0) {
    return {
      matches: true,
    };
  }

  // aggregate results
  return conditions.reduce(
    (acc, cond) => {
      const result = checkConditionRules(cond, params);
      acc.matches = acc.matches && result.matches;
      acc.results.push(result);
      return acc;
    },
    {
      matches: true,
      results: [],
    }
  );
}

/**
 * Conditions inside a group are grouped with OR -> we can return early if we find a single matching condition
 * @param {SurveyTriggerConditionRule[]} rules Trigger condition rules, defined by Console UI
 * @param {Record<string, any>} params List of tracking properties and their values
 */
export function checkConditionRules(rules, params) {
  let paramValue;
  let matchingCondition = {
    matches: false,
    c: undefined, // is cookie
    n: undefined, // name
    o: undefined, // operator
    v: undefined, // value
    value: undefined, // actual prop value
  };
  const getConditionLog = (cond, value) => ({
    matches: true,
    c: cond.c,
    n: cond.n,
    o: cond.o,
    v: cond.v,
    value,
  });

  // rules inside a condition are grouped with OR -> we can return early if we find a single matching condition
  rules.some(cond => {
    // Get the value using cookies or properties
    if (undefined !== cond.c && Boolean(cond.c)) {
      paramValue = cookieStorage.get(cond.n);
    } else {
      paramValue = params[cond.n];
    }

    if (cond.o === 'is_null') {
      if (undefined === paramValue || paramValue === null) {
        matchingCondition = getConditionLog(cond, paramValue);
        return true;
      }
    } else if (cond.o === 'is_not_null') {
      if (paramValue !== null && paramValue !== undefined) {
        matchingCondition = getConditionLog(cond, paramValue);
        return true;
      }
    } else if (undefined !== paramValue && paramValue !== null) {
      // operator
      switch (cond.o) {
        case 'equals':
          if (paramValue.toString() === cond.v.toString()) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        case 'not_equals':
          if (paramValue.toString() !== cond.v.toString()) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        // If value can't be parsed to number, Number() will give NaN. Comparing NaNs will always fail
        case 'greater_than':
          if (Number(paramValue) > Number(cond.v)) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        case 'less_than':
          if (Number(paramValue) < Number(cond.v)) {
            matchingCondition = getConditionLog(cond, paramValue);
            return true;
          }
          break;

        default:
          return false;
      }
    }

    return false;
  });

  return matchingCondition;
}

export function isInLockoutPeriod() {
  const lqDefinition = getLQDefinition();
  const now = getNow();

  return now <= lqDefinition.nextSurveyShow;
}

export function isSurveyShown(surveyId) {
  if (surveyId) {
    const lqDefinition = getLQDefinition();

    return lqDefinition.showedSurveys.includes(surveyId.toString());
  }

  return false;
}

/**
 * @param {import('../../../laquesis/survey/surveyActions').surveyData} survey
 */
export function markSurveyAsShown(survey) {
  const lqDefinition = getLQDefinition();
  const lockoutInSeconds = survey.config?.next_survey_allowed_in_sec || 0;
  if (!isSurveyShown(survey.id) && !ninjaConfig.qaMode) {
    // feedback surveys ignore lockout period
    if (survey.type === 'default') {
      // add to shown surveys
      lqDefinition.showedSurveys.push(survey.id);
      // Store the next time to show another survey
      lqDefinition.nextSurveyShow = getNow() + lockoutInSeconds;
    }

    writeLQDefinition(lqDefinition);

    debugSurvey(SURVEY_MARK_SHOWN, survey.id, {
      lock_until: lqDefinition.nextSurveyShow,
      shown_to_user: lqDefinition.showedSurveys.join(TEST_DIVIDER),
    });
  }

  return lqDefinition;
}

/**
 * @param {number} surveyId
 * @param {string} [link]
 * @returns {Promise<Survey>}
 */
export function getSurveyData(surveyId, link) {
  return new window.Promise(async resolve => {
    let suburl = link;
    let params = {};

    if (!link) {
      suburl = SPLITTER_CDN + '/survey';
      params.id = surveyId;
      params.cc = hydraConfig.params.cC;
      params.br = hydraConfig.params.bR;
      params.ch = ninjaConfig.platform;
    }
    const url = suburl + '?' + objectToQueryString(params);
    const response = await ajaxCallPromise('get', url);

    debugSurvey(SURVEY_LOAD, surveyId, {
      splitter_url: url,
    });
    resolve(response);
  });
}
