export const NINJA_CDN = 'https://ninja.data.olxcdn.com';

export const SPLITTER_CDN = 'https://laquesis.data.olxcdn.com';

export const JSQR_URL = 'https://unpkg.com/jsqr@1.4.0/dist/jsQR.js';

// do not include version in the path -> always load the latest
export const WEB_VITALS_URL = 'https://unpkg.com/web-vitals/dist/web-vitals.iife.js';

export const HYDRA_HOST = 'tracking.olx-st.com/h/v2/';

// Load `/next/` suffix until all regions are migrated
// TODO: Update deploy scripts for qa and survey when done
export const LAQUESIS_QA_URL = NINJA_CDN + '/laquesisqa.js';

export const LAQUESIS_SURVEY_JS_URL = NINJA_CDN + '/laquesisSurvey.js';
export const LAQUESIS_SURVEY_CSS_FILENAME = 'laquesis-survey';

export const Trackers = {
  Hydra: 'H', // Hydra
  Laquesis: 'LQ', // Laquesis
  Mixpanel: 'M', // Mixpanel
  GTM: 'G', // GTM
  GA: 'GA', // GA
  GTAG: 'GTAG', // GA4 a.k.a. GTAG
  Clevertap: 'CT', // Clevertap,
  Braze: 'BRAZE', // Braze

  /* Apps only */
  Facebook: 'F', // Facebook
  Appsflyer: 'A', // Appsflyer
  Adjust: 'AD', // Adjust
  NewRelic: 'NR', // NewRelic
};

export const EVENTS = {
  LAQUESIS_READY: 'laquesis/ready',
  LAQUESIS_FLAG_ASSIGNMENT: 'laquesis/flagAssignment',
  LAQUESIS_EXP_ASSIGNMENT: 'laquesis/experimentAssignment',
  LAQUESIS_EXP_IMPRESSION: 'laquesis/experimentImpression',
};

export const Channel = {
  Mobile: 'm',
  Desktop: 'd',
  DesktopMobile: 'w',
  Android: 'a',
  iOS: 'i',
};

export const ChannelName = {
  Mobile: 'mobile-html5',
  Desktop: 'desktop',
};

/* eslint-disable */
export const LATIN_MAP = {
  '\u00e1': 'a', // á
  '\u00c1': 'A', // Á
  '\u00e9': 'e', // é
  '\u00c9': 'E', // É
  '\u00ed': 'i', // í
  '\u00cd': 'I', // Í
  '\u00f3': 'o', // ó
  '\u00d3': 'O', // Ó
  '\u00fa': 'u', // ú
  '\u00da': 'U', // Ú
  '\u00f1': 'n', // ñ
  '\u00d1': 'N', // Ñ
};
/* eslint-enable */

export const LQ_EVENTS = {
  TEST_ASSIGNMENT: 'test_assignment',
  TEST_IMPRESSION: 'test_impression',
  FLAG_ASSIGNMENT: 'flag_assignment',
};

export const TEST_DIVIDER = '#';
export const VARIANT_DIVIDER = '@';
export const VALUE_DIVIDER = '|';

export const LQ_SURVEYS_STORAGE_KEY = 'laquesissu';
// old keys
export const LQ_SURVEY_TRIGGERS_STORAGE_KEY = '_lq_surveys_';
export const LQ_SURVEY_TYPES_STORAGE_KEY = '_lq_surveys_type_';
