/**
 * Clean a given cookie from all possible domains and paths
 * @param {string} cName - the cookie name
 * @param {string[]} [allowedDomains=[]] - list of domains to skip cleanup
 * @param {string[]} [allowedPaths=[]] - list of paths to skip cleanup
 */
export function cleanCookie(cName, allowedDomains = [], allowedPaths = []) {
  const d = window.location.hostname.split('.');
  const cookiesToRemove = [encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'];
  let cookieBase;

  // Valid domains require at least one dot
  while (d.length > 1) {
    const domain = d.join('.');
    let domainsWithPrefix = [];

    if (Array.isArray(allowedDomains) && !allowedDomains.includes(domain)) {
      domainsWithPrefix = [domain];
    }

    if (!domain.match(/^www\./)) {
      domainsWithPrefix.push('www.' + domain);
      domainsWithPrefix.push('.www.' + domain);
    }

    for (let j = 0; j < domainsWithPrefix.length; j++) {
      cookieBase = encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + domainsWithPrefix[j] + ' ;path=';

      // clean cookie at root path
      if (!cookiesToRemove.includes(cookieBase + '/')) {
        cookiesToRemove.push(cookieBase + '/');
      }

      let p = location.pathname.split('/');
      // clean cookie at all possible sub-paths
      while (p.length > 0) {
        const cPath = p.join('/');
        if (cPath !== '/' && cPath !== '' && !allowedPaths.includes(cPath)) {
          cookiesToRemove.push(cookieBase + cPath);
        }

        p.pop();
      }
    }

    d.shift();
  }

  for (let i = 0; i < cookiesToRemove.length; i++) {
    // console.log('Removing ', cName, cookiesToRemove[i]);
    document.cookie = cookiesToRemove[i];
    // TODO: use ePrivacy SDK to modify cookies
    // cookieStorage.remove();
  }

  return cookiesToRemove;
}
