import { LATIN_MAP } from '../const';

// Encode value
export function eucex(value) {
  let encode;

  try {
    encode = encodeURIComponent(decodeURIComponent(value));
  } catch (ignore) {
    encode = '';
  }

  return encode;
}

// Decode value
export function deucex(value) {
  let decode;

  try {
    decode = decodeURIComponent(value);
  } catch (ignore) {
    decode = '';
  }

  return decode;
}

/**
 * Inserts a script in the document and calls callback
 * @param {string} [url]
 * @param {string} [id]
 * @param {Function} [callback]
 * @param {string} [content] - if provided, will be added as innerHTML to the script element
 */
export function loadScript(url, id, callback, content) {
  /**
   * @type {HTMLScriptElement}
   */
  let script;
  let qa = 'createElement';
  let docHead;
  let docBody;
  let docScript;
  let oldTag;

  if (id) {
    oldTag = document.getElementById(id);
    if (oldTag) {
      // Already loaded
      if (callback) {
        callback(url);
      }
      return;
    }
  }

  if (typeof document[qa] === 'function') {
    // Only support browser that have document.createElement function
    script = document[qa]('script');
    script.type = 'text/javascript';
    script.async = true;
    if (id) {
      script.id = id;
    }

    if (callback) {
      if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback(url);
          }
        };
      } else {
        //Others
        script.onload = function () {
          callback(url);
        };
      }
    }

    if (url) {
      script.src = url;
    }

    if (content) {
      script.innerHTML = content;
    }

    docHead = document.getElementsByTagName('head');
    docBody = document.getElementsByTagName('body');
    docScript = document.getElementsByTagName('script');

    if (docHead && docHead[0]) {
      docHead[0].appendChild(script);
    } else if (docBody && docBody[0]) {
      docBody[0].appendChild(script);
    } else if (docScript && docScript[0] && docScript[0].parentNode) {
      docScript[0].parentNode.insertBefore(script, docScript[0]);
    }
  }
}

/**
 * Load CSS styles
 * @param {string} url
 * @param {string} id
 * @param {() => void} [callback]
 * @param {(e: string | Event) => void} [errorCallback]
 */
export function loadCSS(url, id, callback, errorCallback = null) {
  if (document.getElementById(id)) {
    callback();
  } else {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    link.id = id;
    link.onload = () => {
      if (typeof callback === 'function') {
        callback();
      }
    };
    link.onerror = e => {
      if (typeof errorCallback === 'function') {
        errorCallback(e);
      }
    };

    document.head.appendChild(link);
  }
}

export function getCurrentPath() {
  let pathname = window.location.pathname;
  if (pathname.charAt(0) === '/' && pathname.length > 1) {
    pathname = pathname.slice(1);
  }
  if (pathname.charAt(pathname.length - 1) === '/' && pathname.length > 1) {
    pathname = pathname.slice(0, pathname.length - 1);
  }
  if (pathname.search(/\./) > 0) {
    pathname = pathname.replace(/(\.[a-z]+$)/, '');
  }
  if (pathname === '/' || pathname === '') {
    pathname = 'home';
  }
  return pathname;
}

/**
 * Send ajax call
 * @param {'get' | 'post'} method The method of request GET/POST
 * @param {String} url The URL to which to send the request
 * @param {Function} callback callback function on success
 * @param {any} params TODO: do we need this params here? can't find any place where we pass this params
 * @param {Function} onError callback function on error
 * @returns {void}
 */
export function ajaxCall(method, url, callBack, params, onError) {
  let xdr;
  let xmlhttp;

  if (window.XDomainRequest) {
    xdr = new XDomainRequest();

    if (xdr) {
      xdr.onload = function () {
        callBack(xdr.responseText, params);
      };

      xdr.onerror = function () {
        if (onError) {
          onError('xdr.onerror ' + method + ' ' + url);
        }
      };

      xdr.open(method, url, true);
      xdr.send();
    }
  } else {
    if (!window.XMLHttpRequest) {
      // code for IE6, IE5
      try {
        xmlhttp = new ActiveXObject('MSXML2.XMLHTTP');
      } catch (ignore) {
        xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
      }
    } else {
      // code for IE7+, Firefox, Chrome, Opera, Safari
      xmlhttp = new XMLHttpRequest();
    }

    try {
      xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState === 4) {
          callBack(xmlhttp.responseText, params);
        }
      };

      xmlhttp.open(method, url, true);
      xmlhttp.onerror = function () {
        if (onError) {
          onError('xmlhttp.onerror ' + method + ' ' + url);
        }
      };
      xmlhttp.send();
    } catch (err) {
      if (onError) {
        onError((err || '') + ' ' + method + ' ' + url);
      }

      callBack('', params);
    }
  }
}

/**
 * Send ajax call and returns Promise
 * @param {'get' | 'post'} method The method of request GET/POST
 * @param {String} url The URL to which to send the request
 * @returns {Promise<any>}
 */
export function ajaxCallPromise(method, url) {
  return new window.Promise((resolve, reject) => {
    ajaxCall(method, url, resolve, null, reject);
  });
}

export function getProtocol(optionSsl, optionNormal) {
  return window.location.protocol === 'https:' ? 'https://' + (optionSsl ? optionSsl : '') : 'http://' + (optionNormal ? optionSsl : '');
}

// TODO: Consider using external unicode normalization lib
export function convertToLatin(string) {
  return string.replace(/[^A-Za-z0-9\-_]/g, function (a) {
    return LATIN_MAP[a] || a;
  });
}

/**
 * Converts object to query-style string
 * @param {Record<string, any>} obj Data object
 * @returns {String}
 */
export function objectToQueryString(obj) {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

/**
 * Get current time in seconds
 */
export function getNow() {
  return Math.round(new Date().getTime() / 1000);
}
