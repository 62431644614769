/**
 * Triggers CustomEvent on an element.
 * CustomEvent is polyfilled, if missing.
 * @param {string} eventName
 * @param {Record<string, unknown>} [data]
 * @param {HTMLElement} [element=document]
 */
export function triggerCustomEvent(eventName, data, element) {
  const el = element ? element : document;
  const detail = data ? data : {};
  const event = new CustomEvent(eventName, { detail: detail });

  el.dispatchEvent(event);
}
