import { ninjaConfig } from '../../config/ninja';
import { deucex } from '../../utils';

// Internal function - Collect the current host
export function getHost() {
  return window.location.host || null;
}

// Internal function - Collect the current hash
export function getHash() {
  return window.location.hash || null;
}

// Internal function - Collect the referrer
export function getReferrer() {
  return document.referrer || null;
}

/**
 * Internal function - Collect the pageName
 * @param {string} pageName
 * @returns string
 */
export function getPageName(pageName) {
  return pageName ? deucex(pageName.toLowerCase()) : null;
}

/**
 * Internal function - Collect the eventName
 * @param {Array} eventParams
 * @returns Object
 */
export function getEventData(eventParams = []) {
  const eventData = {};

  if (Array.isArray(eventParams)) {
    if (eventParams[0]) {
      eventData.category = deucex(eventParams[0]);
    }
    if (eventParams[1]) {
      eventData.action = deucex(eventParams[1]);
    }
    if (eventParams[2]) {
      eventData.label = deucex(eventParams[2]);
    }
    if (eventParams[3]) {
      eventData.value = deucex(eventParams[3]);
    }
    if (eventParams[4]) {
      eventData.interact = deucex(eventParams[4]);
    }
  }

  return eventData;
}

// Check if support localStorage
export function isStorageAvailable() {
  let storage;
  try {
    let x = '__storage_test__';
    storage = window.localStorage;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

// Collect the finish call from the trackers
export function collectCalls() {
  ninjaConfig.linkCount = ninjaConfig.linkCount + 1;
  if (ninjaConfig.linkCount === ninjaConfig.linkTotalCount) {
    ninjaConfig.linkCallBack.call(null, ninjaConfig.linkEventName);
    ninjaConfig.linkCallBack = null;
    ninjaConfig.linkTotalCount = 0;
    clearTimeout(ninjaConfig.linkSetTimeout);
  }
}
