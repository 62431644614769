import { ninjaConfig } from './ninja';
import { regionConfig } from './region';

/**
 * @typedef {Object} HydraConfig
 * @prop {string} path - Main stream
 * @prop {string} error_path - Error stream
 * @prop {string} survey_path - Surveys stream
 * @prop {string} [vitals_path] - Web-vitals stream
 * @prop {object} params
 * @prop {string} params.bR - Site brand
 * @prop {string} params.cC - Site country
 * @prop {boolean} [vitals] - Wether web-vitals collection is enabled
 * @prop {import('@olxeu-eprivacy-storage/js/storage').CookieScope} [eprivacy_scope] - EU sites must have scope in order to be considered as required to comply with GDPR and load cookie consent definitions
 * @prop {boolean} asyncSurveyStyles - load survey styles dynamically
 * @prop {string} surveyTheme - themed styles
 */

/**
 * @type {HydraConfig}
 */
export const hydraConfig = regionConfig.custom[ninjaConfig.siteUrl].config.H
  ? Object.assign({}, regionConfig.config.H, regionConfig.custom[ninjaConfig.siteUrl].config.H)
  : regionConfig.config.H;
