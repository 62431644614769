const MULTIPLIERS = {
  s: 1000,
  min: 60 * 1000,
  h: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  month: 31 * 24 * 60 * 60 * 1000,
  year: 365 * 24 * 60 * 60 * 1000,
};
/**
 * Gets Date object with added time to be used in cookie expiration
 * @param {number} value Numberical value
 * @param {'s' | 'min' | 'h' | 'day' | 'month' | 'year'} unit Expiration unit that will be used to convert the value
 * @returns {Date}
 */
export function getCookieExpirationDate(value, unit = 's') {
  const date = new Date();
  const multiplier = MULTIPLIERS[unit] || -1;

  date.setTime(date.getTime() + value * multiplier);

  return date;
}
