import { hydraConfig } from '../../../config/hydra';
import { ninjaConfig } from '../../../config/ninja';
import { regionConfig } from '../../../config/region';
import {
  Channel,
  ChannelName,
  HYDRA_HOST,
  LQ_SURVEYS_STORAGE_KEY,
  LQ_SURVEY_TRIGGERS_STORAGE_KEY,
  LQ_SURVEY_TYPES_STORAGE_KEY,
  Trackers,
} from '../../../const';
import { getLQDefinition, writeLQDefinition } from '../../../cookies/lqstatus';
import { isStorageAvailable, trackError, trackWithBeacon } from '../../../core/utils';
import { makeMapping } from '../../../mapping';
import { eucex, getNow, getProtocol } from '../../../utils';
import { convertString, currentPlatform } from '../../hydra';
import { canShowSurvey, getSurveyData, isSurveyAvailable, markSurveyAsShown } from './surveyConditions';
import {
  SURVEY_ALREADY_ACTIVE,
  SURVEY_LOAD_ERROR,
  SURVEY_RENDER,
  SURVEY_RENDER_START,
  SURVEY_TRIGGER_MATCH,
  debugSurvey,
} from './surveyDebug';
import { loadSurveyScript } from './surveyLoadScript';
import * as storage from './surveyStorage';
import { loadSurveyStyles } from './surveyStyles';

let isReady = false;
let surveysData = null;
let counter = 0;
let activeSurvey = false;
const withLocalStorage = isStorageAvailable();

export function init() {
  let mustRefresh = false;

  // if localStorage is not available, surveys will not work
  if (!withLocalStorage) {
    storage.clear();
    return false;
  }

  if (!isReady) {
    // cleanup old items from store
    localStorage.removeItem(LQ_SURVEY_TRIGGERS_STORAGE_KEY);
    localStorage.removeItem(LQ_SURVEY_TYPES_STORAGE_KEY);

    const storedSurveys = localStorage.getItem(LQ_SURVEYS_STORAGE_KEY);
    surveysData = parseLaquesisSuData(storedSurveys);

    if (!surveysData) {
      mustRefresh = true;
      surveysData = [];
    }

    setSurveys(surveysData);
    isReady = true;
  }

  return mustRefresh;
}

export async function setQASurvey(id) {
  if (id) {
    isReady = true;
    ninjaConfig.qaMode = true;

    // show survey with timeout
    const data = await getSurveyData(id);

    if (!data) {
      trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
      return;
    }

    const surveyData = JSON.parse(data);
    setSurveys([surveyData]);
  } else {
    ninjaConfig.qaMode = false;
  }
}

export function cleanup() {
  storage.clear();
  localStorage.removeItem(LQ_SURVEYS_STORAGE_KEY);
}

export function trackEvent(params) {
  return trackPage(params);
}

export function trackLinkEvent(params) {
  return trackEvent(params);
}

export async function trackPage(params) {
  init();

  let eventData = [];
  let eventName;
  let newValues;

  if (params.eventData.category) {
    if (params.eventData.category) {
      eventData.push(params.eventData.category);
    }
    if (params.eventData.action) {
      eventData.push(params.eventData.action);
    }
    if (params.eventData.label) {
      eventData.push(params.eventData.label);
    }
    if (params.eventData.value) {
      eventData.push(params.eventData.value);
    }

    eventName = eucex(convertString(eventData.join('_')));
    newValues = makeMapping(Trackers.Hydra, 'trackEvent', eventName);
    if (newValues.key === false || newValues.value === false) {
      return false;
    }
    eventName = newValues.value;
  }
  if (params.pageName) {
    // Add original trackPage
    newValues = makeMapping(Trackers.Hydra, 'trackPage', params.pageName);
    if (newValues.key === false || newValues.value === false) {
      return false;
    }
    eventName = eucex(newValues.value);
  }

  const surveys = storage.getSurveysWithTriggerEvent(eventName);
  if (surveys.length > 0) {
    for (const id of surveys) {
      const triggerResult = canShowSurvey(id, eventName, params);

      if (triggerResult.matches) {
        ninjaConfig.laquesisQa = false;
        triggerResult.event = eventName;
        triggerResult.active_surveys = surveys;

        debugSurvey(SURVEY_TRIGGER_MATCH, id, {
          trigger_result: triggerResult || 'Missing trigger match!',
        });
        console.log(triggerResult);
        try {
          const result = await showSurvey(id);
          if (result) {
            // survey can be shown -> no point to continue checking other surveys
            break;
          }
        } catch (e) {
          //
        }
      }
    }
  }

  // if (ninjaConfig.listOfActiveSurveys[eventName]) {
  //   for (const survey of ninjaConfig.listOfActiveSurveys[eventName]) {
  //     const triggerResult = canShowSurvey(
  //       survey.id,
  //       params,
  //       survey.cond,
  //       ninjaConfig.listOfActiveSurveys,
  //       activeSurveysTypes,
  //       ninjaConfig.qaMode
  //     );

  //     if (triggerResult.matches) {
  //       ninjaConfig.qaMode = false;
  //       triggerResult.event = eventName;
  //       triggerResult.active_surveys = ninjaConfig.listOfActiveSurveys[eventName];

  //       debugSurvey(SURVEY_TRIGGER_MATCH, survey.id, {
  //         trigger_result: triggerResult || 'Missing trigger match!',
  //       });
  //       // survey can be shown -> no point to continue checking other surveys
  //       if (showSurvey(survey.id)) {
  //         break;
  //       }
  //     }
  //   }
  // }

  return true;
}

/**
 * Process splitter response (or localstorage stored items) and update storage with active surveys
 * @param {any[]} surveys
 */
export function setSurveys(surveys = []) {
  const lqDefinition = getLQDefinition();
  const lqDefinitionTmp = lqDefinition?.showedSurveys || [];
  let lqDefinitionLocal = [];

  const mappedSurveys = surveys.map(s => {
    // Clean up the showed surveys that are not active anymore
    if (lqDefinitionTmp.includes(s.id.toString())) {
      lqDefinitionLocal.push(s.id.toString());
    }

    return {
      id: Number(s.id),
      triggers: s.triggers,
      type: s.type,
    };
  });

  mappedSurveys.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));

  storage.setSurveys(mappedSurveys);

  lqDefinition.showedSurveys = lqDefinitionLocal;
  writeLQDefinition(lqDefinition);

  return mappedSurveys;
}

// Read and parse the surveys cookie
export function parseLaquesisSuData(data = '') {
  try {
    if (data) {
      const parsed = JSON.parse(data);

      if (Array.isArray(parsed)) {
        return parsed;
      }
    }
  } catch {
    return null;
  }

  return null;
}

// Track
export function trackSurvey(eventName, survey_id, survey_page_id, question_id, question_value) {
  const params = {};
  const survey = storage.getSurvey(survey_id);

  // Finish current survey
  if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
    if (activeSurvey === true) {
      activeSurvey = false;
    }
  }

  // Path
  const url = getProtocol() + HYDRA_HOST + hydraConfig.survey_path;

  // Properties
  params.eN = eventName;
  params.sl = ninjaConfig.currentSessionLong;
  params.s = ninjaConfig.currentSession;
  params.survey_id = survey_id;
  params.survey_type = survey.type;
  params.survey_page_id = survey_page_id;
  if (undefined !== question_id) {
    params.survey_question_id = question_id;
  }
  if (undefined !== question_value) {
    params.survey_question_value = question_value;
  }

  // Config values, countries and regions
  params.cC = hydraConfig.params.cC;
  params.bR = hydraConfig.params.bR;

  if (ninjaConfig.platform === Channel.Mobile) {
    params.cH = ChannelName.Mobile;
  } else if (ninjaConfig.platform === Channel.Desktop) {
    params.cH = ChannelName.Desktop;
  } else if (currentPlatform) {
    params.cH = currentPlatform;
  } else {
    params.cH = Channel.DesktopMobile;
  }

  // Matrix Version
  if (undefined !== regionConfig.version) {
    params.mv = regionConfig.version;
  }

  // Environment
  if (ninjaConfig.environment !== 'production') {
    params.env = 'dev';
  }

  // Timestamp
  params.t = new Date().getTime();

  trackWithBeacon(url, params);
}

export async function showSurvey(surveyId, link) {
  // Another survey is running
  if (activeSurvey) {
    debugSurvey(SURVEY_ALREADY_ACTIVE, surveyId, {
      active_survey: `${surveyId} skipped, other already active`,
    });
    return window.Promise.reject(false);
  }

  activeSurvey = true;

  counter = getNow();

  // forward the data and the trigger results
  try {
    const [data] = await Promise.all([getSurveyData(surveyId, link), loadSurveyStyles(), loadSurveyScript()]);
    renderSurvey(data);
  } catch (e) {
    activeSurvey = false;
    debugSurvey(SURVEY_LOAD_ERROR, surveyId, {
      error: e,
    });

    return window.Promise.reject(false);
  }

  return window.Promise.resolve(true);
}

export function renderSurvey(jsonSurveyData) {
  return new Promise((resolve, reject) => {
    if (jsonSurveyData === null || jsonSurveyData === '') {
      trackError('fetchSurveyError', 'Survey', 'renderSurvey', 'Unable to connect');
      reject();
    } else {
      /**
       * @type {import('../../../laquesis/survey/surveyActions').surveyData}
       */
      const surveyData = JSON.parse(jsonSurveyData);
      let delayRender = 3;

      if (surveyData.config) {
        if (typeof surveyData.config.delay_render_in_sec === 'number') {
          delayRender = surveyData.config.delay_render_in_sec;
        }

        // Render with delay
        // if (!isLaquesisQa) {

        const timeLoading = getNow() - counter;
        let timeRemaining = Math.max(0, delayRender - timeLoading);
        timeRemaining = timeRemaining > 0 ? timeRemaining * 1000 : 0;

        debugSurvey(SURVEY_RENDER_START, surveyData.id, {
          delay_render_in_sec: delayRender,
        });

        setTimeout(() => {
          showSurveyToUser(surveyData);
          resolve();
        }, timeRemaining);
      }
    }
  });
}

export function showSurveyToUser(surveyData) {
  // Use altered `trackSurvey` callback when we are in QA mode
  debugSurvey(SURVEY_RENDER, surveyData.id, {
    qa: ninjaConfig.qaMode,
  });

  const trackSurveyFn = ninjaConfig.qaMode
    ? function (eventName) {
        // Finish current survey
        if (eventName !== 'survey_show' && eventName !== 'survey_push_show') {
          if (activeSurvey === true) {
            activeSurvey = false;
          }
        }
      }
    : trackSurvey;

  window.laquesisSurvey(surveyData, trackSurveyFn);

  if (ninjaConfig.qaMode) {
    ninjaConfig.qaMode = false; // reset QA mode
  } else {
    // Mark this survey as shown and save lqDefinition
    markSurveyAsShown(surveyData);
  }
}

export function setupWindow() {
  // Expose function
  window.laquesisShowSurvey = function (id) {
    if (isSurveyAvailable(id)) {
      ninjaConfig.qaMode = false;
      return showSurvey(id, null, { result: `Manual trigger - window.laquesisShowSurvey ${id}` });
    }
    return window.Promise.reject('Survey not available, already shown or in lockout period');
  };

  window.isSurveyAvailable = function (id) {
    return isSurveyAvailable(id);
  };

  window.laquesisShowSurveyByLink = function (link) {
    ninjaConfig.qaMode = true;
    return showSurvey(0, link);
  };
}
