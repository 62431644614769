/**
 * Pass the consent to be used by GTM/GTAG
 * @param {string} [dataLayerName] - name of the dataLayer
 */
export function setDefaultGtagConsents(dataLayerName = 'dataLayer') {
  const dataLayer = window[dataLayerName];
  if (!Array.isArray(dataLayer)) {
    return;
  }

  const gtag = function gtag() {
    // we want to make sure it's processed before other items from the dataLayer
    dataLayer.unshift(arguments);
  };

  const verb = 'granted';
  gtag('consent', 'default', {
    ad_storage: verb,
    ad_user_data: verb,
    ad_personalization: verb,
    analytics_storage: verb,
  });
}
