import { createCookieStorage } from '@olxeu-eprivacy-storage/js/cookie';
import { CookieScope, ENV, getReservoirLink } from '@olxeu-eprivacy-storage/js/storage';
import { hydraConfig } from '../config/hydra';
import { ninjaConfig } from '../config/ninja';
import { ajaxCall } from '../utils';
import { isEprivacyStorageRequired } from './isEprivacyStorageRequired';
import { REQUIRED_VERSION } from './required-version';

/**
 * @typedef {import('@olxeu-eprivacy-storage/js/storage').CookieStorage} CookieStorage
 */

/* eslint-disable no-unused-vars */
/**
 * Get empty facade.
 * @returns {CookieStorage}
 */
export function getEmptyStorage() {
  return {
    set: (cookieName, value, options) => {
      console.log('empty storage');
      return undefined;
    },
    get: cookieName => {
      console.log('empty storage');
      return undefined;
    },
    remove: (cookieName, options) => {
      console.log('empty storage');
    },
  };
}
/* eslint-enable no-unused-vars */

/**
 * Local cookie storage instance.
 * @type {CookieStorage}
 */
export let cookieStorage = getEmptyStorage();
export let isReady = false;

/**
 * Every cookie should define these properties
 * @type {import('@olxeu-eprivacy-storage/js/storage').CookieAttributes}
 */
export const defaultCookieAttributes = {
  sameSite: 'Lax',
  secure: location.protocol === 'https:',
};

/**
 * Init local cookie storage - either use the version from `window` or load the definitions
 * @param {(storage: CookieStorage) => void} callbackFn
 */
export function initCookieStorage(callbackFn = () => {}) {
  if (isReady) {
    callbackFn(cookieStorage);
    return;
  }

  if (isEprivacyStorageRequired()) {
    if (typeof ninjaConfig.getEprivacySDK === 'function') {
      cookieStorage = createStorageProxy(ninjaConfig.getEprivacySDK());

      isReady = true;
      callbackFn(cookieStorage);
    } else if (window.eprivacyStorage && window.eprivacyStorage[REQUIRED_VERSION]) {
      cookieStorage = createStorageProxy(window.eprivacyStorage[REQUIRED_VERSION]);

      isReady = true;
      callbackFn(cookieStorage);
    } else {
      loadCookieStorage(storage => {
        cookieStorage = createStorageProxy(storage);

        if (cookieStorage) {
          if (!window.eprivacyStorage) {
            window.eprivacyStorage = {};
          }

          window.eprivacyStorage[REQUIRED_VERSION] = storage;

          isReady = true;
          callbackFn(cookieStorage);
        }
      });
    }
  } else {
    // If cookie consent is not required for this region, return cookie storage without consent restrictions
    cookieStorage = createStorageProxy(createCookieStorage(false));
    isReady = true;
    callbackFn(cookieStorage);
  }
}

/**
 * Fetches definition and fully inits the storage
 * @param {(storage: CookieStorage) => void} callbackFn
 */
export function loadCookieStorage(callbackFn) {
  if (hydraConfig.eprivacy_scope) {
    const url = getReservoirLink(CookieScope[hydraConfig.eprivacy_scope], hydraConfig.debug ? ENV.STG : ENV.PRD);

    ajaxCall(
      'get',
      url,
      function (data) {
        let categories = JSON.parse(data);
        let storage = createCookieStorage(true, categories);
        callbackFn(storage);
      },
      null,
      function (e) {
        console.error('Ninja can not load cookie category definitions!', url, e);
        callbackFn(getEmptyStorage());
      }
    );
  } else {
    console.error('Ninja can not load cookie category definitions - missing `eprivacy_scope` for current region!');
    callbackFn(getEmptyStorage());
  }
}

/**
 * Apply default cookie attributes via Proxy
 * @param {CookieStorage} storage
 */
function createStorageProxy(storage) {
  /**
   * @type {ProxyHandler<CookieStorage>}
   */
  const handler = {
    get(target, prop, receiver) {
      const value = target[prop];
      if (value instanceof Function) {
        return function (...args) {
          const newArgs = [...args];

          if (prop === 'set') {
            // args were ommited
            if (newArgs.length === 2) {
              newArgs.push(defaultCookieAttributes);
            } else {
              newArgs[2] = {
                ...defaultCookieAttributes,
                ...newArgs[2],
              };
            }
          }

          return value.apply(this === receiver ? target : this, newArgs);
        };
      }
      return value;
    },
  };

  return new window.Proxy(storage, handler);
}
